<template>
  <div class="relative">
    <XPBXLoader v-if="isLoading" />
    <HeaderSection :showXPBXLogo="true" />
    <section class="flex flex-col md:flex-row flex-wrap page-content">
      <Sidebar />
      <main class="flex-1 overflow-y-hidden">
        <slot></slot>
      </main>
    </section>
  </div>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import Sidebar from "@/modules/xpbx/components/home/sidebar/Sidebar";
import HeaderSection from "@/modules/common/components/header-section/index";
import XPBXLoader from "@/modules/xpbx/components/home/UI/XPBXLoader/XPBXLoader.vue";

export default {
  name: "XPBXLayout",

  components: {
    Sidebar,
    XPBXLoader,
    HeaderSection,
  },

  setup() {
    const store = useStore();

    const isLoading = computed(() => store.state.xadmin.isGlobalLoader);

    return {
      store,
      isLoading,
    };
  },
};
</script>

<style scoped lang="scss">
.page-content {
  height: calc(100vh - 70px);
  max-height: calc(100vh - 70px);
}
.sidebar {
  .main {
    ul {
      max-height: 100%;
      height: 100%;
      & > li {
        padding: 0.65rem 0.5rem;
        vertical-align: middle;
        &:not(:first-of-type) {
          margin-top: 2px;
        }
        a {
          padding: 0 0.5rem;
        }
      }
    }
  }
}
</style>
