<template>
  <Layout :isAside="true">
    <section class="flex settings-content">
      <Sidebar :data="sidebarData" :dropdowns="sidebarDropdownData" />
      <router-view></router-view>
    </section>
  </Layout>
</template>

<script>
import { useRoute } from "vue-router";
import { inject, onMounted, ref, watch } from "vue";
import Layout from "@/modules/xpbx/components/home/layout/XPBXLayout";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import Sidebar from "@/modules/xpbx/components/UI/sidebar/Sidebar.vue";

export default {
  name: "Home",
  components: {
    Layout,
    PageHeader,
    Sidebar,
  },
  setup() {
    const t = inject("t");
    const route = useRoute();
    const showSidebar = ref(false);

    const sidebarDropdownData = [
      {
        id: 1,
        title: t("xpbx.sidebar.links.general"),
        icon: "fa-regular fa-gear",
        links: [
          {
            title: t("xpbx.sidebar.links.users"),
            link: "/xpbx/settings/users",
          },
          {
            title: t("xpbx.sidebar.links.timezones"),
            link: "/xpbx/settings/timezones",
          },
          {
            title: t("xpbx.sidebar.links.contacts"),
            link: "/xpbx/settings/contacts",
          },
          {
            title: t("xpbx.sidebar.links.templates"),
            link: "/xpbx/settings/templates",
          },
          {
            title: t("xpbx.sidebar.links.sound_files"),
            link: "/xpbx/settings/sound-files",
          },
        ],
      },
      {
        id: 2,
        title: t("xpbx.sidebar.links.softphone"),
        icon: "fa-regular fa-phone",
        links: [
          {
            title: t("xpbx.sidebar.links.allowed_domains"),
            link: "/xpbx/settings/domains",
          },
          {
            title: t("xpbx.sidebar.links.settings"),
            link: "/xpbx/settings/settings",
          },
        ],
      },
      {
        id: 3,
        title: t("xpbx.sidebar.links.pbx"),
        icon: "fa-regular fa-gear",
        links: [
          {
            title: t("xpbx.sidebar.links.did_numbers"),
            link: "/xpbx/settings/did-numbers",
          },
          {
            title: t("xpbx.sidebar.links.extensions"),
            link: "/xpbx/settings/extensions",
          },
          {
            title: t("xpbx.sidebar.links.ring_groups"),
            link: "/xpbx/settings/ring-groups",
          },
          {
            title: t("xpbx.sidebar.links.queue"),
            link: "/xpbx/settings/queues",
          },
          {
            title: t("xpbx.sidebar.links.voicemail"),
            link: "/xpbx/settings/voicemail",
          },
          {
            title: t("xpbx.sidebar.links.blacklist"),
            link: "/xpbx/settings/blacklist",
          },
        ],
      },
      {
        id: 5,
        title: t("xpbx.sidebar.links.routing_destinations"),
        icon: "fa-regular fa-link",
        links: [
          {
            title: t("xpbx.sidebar.links.destinations"),
            link: "/xpbx/settings/destinations",
          },
          {
            title: t("xpbx.sidebar.links.carriers"),
            link: "/xpbx/settings/carriers",
          },
          {
            title: t("xpbx.sidebar.links.flows"),
            link: "/xpbx/settings/flows",
          },

          {
            title: t("xpbx.sidebar.links.routing"),
            link: "/xpbx/settings/routing",
          },
        ],
      },
      {
        id: 4,
        title: t("xpbx.sidebar.links.messages"),
        icon: "fa-regular fa-message",
        links: [
          {
            title: t("xpbx.sidebar.links.qeues"),
            link: "/xpbx/settings/queues",
          },
          {
            title: t("xpbx.sidebar.links.blacklist"),
            link: "/xpbx/settings/blacklist",
          },
          {
            title: t("xpbx.sidebar.links.senders"),
            link: "/xpbx/settings/senders",
          },
        ],
      },
    ];

    const sidebarData = [
      {
        title: t("xpbx.sidebar.links.allowed_domains"),
        icon: "bx bx-home",
        link: "/xpbx/settings/domains",
      },
      {
        title: t("xpbx.sidebar.links.templates"),
        icon: "bx bx-user",
        link: "/xpbx/settings/templates",
      },
      {
        title: t("xpbx.sidebar.links.contacts"),
        icon: "bx bx-phone",
        link: "/xpbx/settings/contacts",
      },
      {
        title: t("xpbx.sidebar.links.sms_senders"),
        icon: "bx bx-file",
        link: "/xpbx/settings/sms-senders",
      },
      {
        title: t("xpbx.sidebar.links.viber_senders"),
        icon: "bx bx-cog",
        link: "/xpbx/settings/viber-senders",
      },
    ];

    const checkRoutes = (name) => {
      const routeName = name.toLowerCase();

      showSidebar.value = routeName === "ivrversiondetail" ? false : true;
    };

    watch(route, () => {
      checkRoutes(route?.name);
    });

    onMounted(() => {
      checkRoutes(route?.name);
    });

    return {
      showSidebar,
      sidebarData,
      sidebarDropdownData,
    };
  },

  onMounted() {
    this.getMainInfo();
  },

  created() {
    this.$i18n.locale = localStorage.getItem("user_lang");
  },
};
</script>
