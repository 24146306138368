<template>
  <aside
    class="bg-app-purple h-full w-full col-span-1 border-r-2 border-gray-500 py-3 overflow-x-hidden"
  >
    <ul
      class="px-3 md:px-0 relative h-full w-12 flex md:flex-col justify-between md:justify-start"
    >
      <li class="md:mb-5">
        <RouterLink :to="{ name: 'xpbx' }">
          <i class="fa-regular fa-house-blank text-gray-600 text-lg"></i>
        </RouterLink>
      </li>
      <!-- <li class="md:mb-5">
        <RouterLink :to="{ name: 'xpbx-users' }">
          <i class="fa-regular fa-user text-gray-600 text-lg"></i>
        </RouterLink>
      </li>
      <li class="md:mb-5">
        <RouterLink :to="{ name: 'xpbx-extensions' }">
          <i
            class="fa-regular fa-phone-arrow-up-right text-gray-600 text-lg"
          ></i>
        </RouterLink>
      </li>
      <li class="md:mb-5">
        <RouterLink :to="{ name: 'xpbx-numbers' }">
          <i
            class="fa-regular fa-phone-arrow-down-left text-gray-600 text-lg"
          ></i>
        </RouterLink>
      </li> -->
      <li class="md:mb-5">
        <RouterLink :to="{ name: 'xpbx-reports' }">
          <i class="fa-regular fa-list-check text-gray-600 text-lg"></i>
        </RouterLink>
      </li>
      <li class="md:mb-5">
        <RouterLink :to="{ name: 'xpbx-settings' }">
          <i class="fa-regular fa-gear text-gray-600 text-lg"></i>
        </RouterLink>
      </li>
      <li
        class="hidden md:block w-8 md:absolute md:top-full md:left-1/2 z-10"
        style="transform: translate(-50%, -100%)"
      >
        <a href="/">
          <img
            src="@/assets/images/header-logo.png"
            alt="Xlinx"
            class="w-full h-full"
          />
        </a>
      </li>
      <li class="block md:hidden w-8">
        <a href="/">
          <img
            src="@/assets/images/header-logo.png"
            alt="Xlinx"
            class="w-full h-full"
          />
        </a>
      </li>
    </ul>
  </aside>
</template>

<script>
export default {
  name: "AsideSection",
};
</script>
