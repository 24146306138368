<template>
  <div class="xpbx-loader"><Loader /></div>
</template>

<script>
import Loader from "@/modules/xpbx/components/UI/loader/index.vue";

export default {
  name: "XPBXLoader",

  components: {
    Loader,
  },
};
</script>

<style lang="scss" scoped>
.xpbx-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  pointer-events: none;
}
</style>