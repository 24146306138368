<template>
  <div class="w-1/4 sidebar mr-3 relative" :class="{ shrink: shrinkSidebar }">
    <ul v-if="!shrinkSidebar">
      <li class="" v-for="(item, index) in dropdowns" :key="index">
        <div
          class="sidebar-link sidebar-list-dark cursor-pointer w-full flex items-center"
          @click="setActiveLink(item.id)"
        >
          <div
            class="sidebar-icon flex items-center justify-center text-white text-sm"
            :class="{ active: activeLink === item.id }"
          >
            <i :class="item.icon" />
          </div>
          <p class="p-3 uppercase">{{ item.title }}</p>
        </div>
        <div
          class="sidebar-dropdown flex flex-col"
          v-show="activeLink === item.id"
        >
          <router-link
            :to="link.link"
            class="p-3 w-full text-left sidebar-dropdown-item"
            v-for="(link, index) in item.links"
            :key="index"
          >
            {{ link.title }}
          </router-link>
        </div>
      </li>
    </ul>

    <div class="action-settings-icon shadow-md" v-if="showArrows">
      <i
        class="fa-light fa-chevrons-right cursor-pointer"
        :class="{ rotate: isOpen }"
        @click="handleActions"
      ></i>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "Sidebar",

  props: {
    data: {
      type: Array,
      required: true,
    },

    dropdowns: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const route = useRoute();
    const activeLink = ref(null);
    const showArrows = ref(false);
    const shrinkSidebar = ref(false);
    const isOpen = ref(false);

    const setActiveLink = (id) => {
      if (activeLink.value === id) {
        activeLink.value = null;
        return;
      }
      activeLink.value = id;
    };

    const generalRoutes = [
      "users",
      "timezones",
      "settingscontact",
      "templates",
      "soundfiles",
    ];

    const softphoneRoutes = ["domains", "settingspage"];
    const routingRoutes = [
      "flowversiondetail",
      "flows",
      "flowdetail",
      "carriers",
      "routing",
      "destinations",
      "carrierdetail",
    ];
    const pbxRoutes = [
      "didnumbers",
      "extensions",
      "ivrdetail",
      "ringgroups",
      "queue",
      "queuedetail",
      "voicemail",
      "blacklist",
      "ringgroupdetail",
    ];
    const messagesRoutes = ["queues", "blacklist", "senders", "vibersenders"];

    const checkRoutes = (name) => {
      const routeName = name.toLowerCase();
      console.log("route name", routeName);

      showArrows.value = routeName === "flowversiondetail" ? true : false;
      shrinkSidebar.value = routeName === "flowversiondetail" ? true : false;
      if (!name) return 1;

      if (generalRoutes.includes(routeName)) return 1;
      if (softphoneRoutes.includes(routeName)) return 2;
      if (pbxRoutes.includes(routeName)) return 3;
      if (messagesRoutes.includes(routeName)) return 4;
      if (routingRoutes.includes(routeName)) return 5;

      return null;
    };

    const handleActions = () => {
      isOpen.value = !isOpen.value;
      shrinkSidebar.value = !shrinkSidebar.value;
    };

    watch(route, () => {
      const activeRoute = checkRoutes(route?.name);
      if (!activeRoute) return;
      if (activeLink.value === activeRoute) return;
      activeLink.value = activeRoute;
    });

    onMounted(() => {
      const activeRoute = checkRoutes(route?.name);
      if (!activeRoute) return;
      if (activeLink.value === activeRoute) return;
      activeLink.value = activeRoute;
    });

    return {
      isOpen,
      activeLink,
      showArrows,
      shrinkSidebar,
      // Methods
      setActiveLink,
      handleActions,
    };
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  border-right: 1px solid rgb(229, 231, 235);
}

div.shrink {
  width: initial;
  /* padding: 0 5px; */
}

.shrink li {
  padding: 10px 0;
}

.sidebar-icon {
  width: 28px;
  height: 28px;
  background-color: #9b9bc5;
  border-radius: 50%;
  margin-left: 12px;
  margin-right: 10px;

  &.active {
    background-color: #1ab394;
  }
}

.sidebar-link {
  border-bottom: 2px solid #fff;
}

.sidebar-link:hover {
  border-bottom: 2px solid #1ab394;
}

.sidebar-dropdown-item {
  border-bottom: 1px solid rgb(229, 231, 235);

  &:hover {
    background-color: rgb(229, 231, 235);
  }
}

.sidebar-link .router-link-active {
  background-color: rgb(229, 231, 235);
}

.router-link-active {
  background-color: rgb(229, 231, 235);
}

.action-settings-icon {
  font-size: 1rem;
  color: #10b981;
  right: -13px;
  position: absolute;
  z-index: 11;
  top: 49%;
  width: 22px;
  height: 22px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rotate {
  transform: rotate(180deg);
}
</style>